import { withStyles } from "@material-ui/core";

const styles = theme => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0
    },
    ".menuicon": {
      color: '#347383',
      border: 'black',
    },
    '.whitetexttypography': {
      color: "#ffffff",
      textShadow: '1px 1px 1px #000000, -1px -1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000',
      fontFamily: 'Roboto Mono, monospace',
      fontWeight: 600,
      '& a': {
        color: '#ffffff',
      },
      '& font': {
        color: '#ffffff',
      },
      '& q': {
        color: 'red',
      },
    },
    '.dialogstrongs': {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(3),
      maxWidth: 420,
      overflow: 'auto',
      color: "#ffffff",
      textShadow: '1px 1px 1px #000000, -1px -1px 1px #000000, -1px 1px 1px #000000, 1px -1px 1px #000000',
      fontFamily: 'Roboto Mono, monospace',
      fontWeight: 600,
      '& a': {
        color: '#ffffff',
      },
      '& font': {
        color: '#ffffff',
      },
      '& q': {
        color: 'red',
      },
    },
    '.versepoppertext': {
      display: 'inline-block',
      padding: '5px',
      textAlign: 'center',
      fontSize: '2.5vw',
      fontWeight: 600,
      color: "#ffffff",
      textShadow: '3px 3px 3px #000000, -3px -3px 3px #000000, -3px 3px 3px #000000, 3px -3px 3px #000000',
      '& sup': {
        display: 'none !important',
      },
    },
    '.fullpagewrapper': {
      position: 'relative',
      height: '100%',
      minHeight: '100vh',
      width: '100%',
      minWidth: '100vw',
    },
    '.commentaryimages': {
      width: '25vw !important',
      height:'20vh !important',
      cursor: 'pointer',
    },
    ".listItemLeftPadding": {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: `${theme.spacing(4)}px !important`
      },
      "@media (max-width:  420px)": {
        paddingLeft: `${theme.spacing(1)}px !important`
      }
    },
    ".pagewrapper": {
      position: "relative",
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    ".container": {
      width: "100%",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 540
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 720
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1170
      }
    },
    ".sectioncontainer": {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        maxWidth: "none !important"
      },
    },
    ".parallaxlayerverseform": {
      [theme.breakpoints.only("sm")]: {
        maxWidth: 540,
        marginRight: '0',
        marginLeft: '0',
      },
      [theme.breakpoints.only("md")]: {
        maxWidth: 540,
        marginRight: '15vw',
        marginLeft: '15vw',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 540,
        marginRight: '30vw',
        marginLeft: '30vw',
      }
    },
    ".parallaxlayerversetext": {
      [theme.breakpoints.only("sm")]: {
        maxWidth: 540,
        marginRight: '0',
        marginLeft: '0',
      },
      [theme.breakpoints.only("md")]: {
        maxWidth: 540,
        marginRight: '0',
        marginLeft: '50vw',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: '45vw',
        marginRight: '0',
        marginLeft: '52vw',
      }
    },
    ".parallaxlayersearchform": {
      [theme.breakpoints.only("sm")]: {
        maxWidth: 500,
        marginRight: '0',
        marginLeft: '0',
      },
      [theme.breakpoints.only("md")]: {
        maxWidth: 500,
        marginRight: '15vw',
        marginLeft: '15vw',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 500,
        marginRight: '30vw',
        marginLeft: '30vw',
      }
    },
    ".parallaxlayersearchtext": {
      [theme.breakpoints.only("sm")]: {
        maxWidth: 540,
        marginRight: '0',
        marginLeft: '0',
      },
      [theme.breakpoints.only("md")]: {
        maxWidth: 540,
        marginRight: '50vw',
        marginLeft: '0',
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 650,
        marginRight: '50vw',
        marginLeft: '0',
      }
    },
    ".parallaxcontainer": {
      width: "100%",
      [theme.breakpoints.only("xs")]: {
        paddingLeft: theme.spacing(0),
      },
      [theme.breakpoints.only("sm")]: {
        paddingLeft: theme.spacing(0),
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down("lg")]: {
        width: "auto"
      },
    },
    '.textboxleft':{
      height: '60vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: '0 auto 0 5%',
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        margin: '0 auto 0 5%',
      },
      [theme.breakpoints.down("lg")]: {
        width: "auto"
      },
    },
    '.textboxright':{
      height: '60vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: '0 5% 0 auto',
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        margin: '0 5% 0 auto',
      },
      [theme.breakpoints.down("lg")]: {
        width: "auto"
      },
    },
    '.formbox': {
      background: 'rgba(255, 255, 255, 0)',
      width: '400px !important',
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        position: 'fixed',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        margin: '0 50% 0 calc(40vw - 450px)',
      },
      [theme.breakpoints.up("lg")]: {
        position: 'fixed',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        margin: '0 50% 0 calc(40vw - 450px)',
      },
    },
    '.textbox':{
      overflowX: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.only("xs")]: {
        width: 'auto',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        width: 'auto',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        width: '50vw',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(10),
        margin: '0 auto 0 50%',
      },
      [theme.breakpoints.up("lg")]: {
        width: '50vw',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(15),
        margin: '0 auto 0 50%',
      },
    },
    '.formboxleft': {
      background: 'rgba(255, 255, 255, 0)',
      maxWidth: '400px',
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        margin: '0 5% 0 auto',
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        margin: '0 5% 0 auto',
      },
      [theme.breakpoints.down("lg")]: {
        width: "auto"
      }
    },
    '.formboxright': {
      background: 'rgba(255, 255, 255, 0)',
      maxWidth: '400px',
      [theme.breakpoints.only("xs")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        margin: '0 auto',
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        margin: '0 auto',
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        margin: '0 auto 0 5%',
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        margin: '0 auto 0 5%',
      },
      [theme.breakpoints.down("lg")]: {
        width: "auto"
      }
    },
    '.predictform': {
      background: 'rgba(255, 255, 255, 0)',
      [theme.breakpoints.up("xs")]: {
        width: '100%',
        margin: 'auto',
      },
      [theme.breakpoints.up("sm")]: {
        width: '75%',
      },
      [theme.breakpoints.up("md")]: {
        width: '50%',
      },
      [theme.breakpoints.up("lg")]: {
        width: '35%',
      },
      [theme.breakpoints.up("xl")]: {
        width: '20%',
        margin: 'auto 5px',
      },
    },
    '.autocompleteWrapper': {
      display: 'flex',
      justifyContent: 'center',
      margin: '1em 0',
    },
    '.toolbarWrapper': {
      justifyContent: "space-between",
      flexWrap: 'wrap',
      [theme.breakpoints.down("md")]: {
        '& >*': {
          margin: '5px auto',
        }
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2)
    },
    ".column": {
      display: "flex",
      flexDirection: 'column',
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2)
    },
    ".container-fluid": {
      width: "100%",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
      maxWidth: 1370
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(18)}px !important`
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(16)}px !important`
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: `${theme.spacing(14)}px !important`
      }
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(18)}px !important`
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(16)}px !important`
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(14)}px !important`
      }
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(18)}px !important`
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(16)}px !important`
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(14)}px !important`
      }
    },
    ".lg-p-toplogin": {
      paddingTop: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(9)}px !important`
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(8)}px !important`
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(7)}px !important`
      }
    },
    ".lg-parallax-top": {
      paddingTop: `${theme.spacing(10)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(9)}px !important`
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(8)}px !important`
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(7)}px !important`
      }
    },
    ".lg-parallaxtext-top": {
      paddingTop: `${theme.spacing(1)}px !important`
    },
  }
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
