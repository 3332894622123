import React, { Fragment, Suspense, lazy, useState, useCallback, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { Route, Switch, HashRouter } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./shared/components/Pace";
import ScrollToTop from "./shared/components/ScrollToTop";
import MouseParticles from "./shared/components/MouseParticles";
import AOS from "aos/dist/aos";
import ConsecutiveSnackbarMessages from "./shared/components/ConsecutiveSnackbarMessages";
import {
  Slide, Fade, Paper, Grow, Collapse
} from "@material-ui/core";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

AOS.init({
  //once: true,
  //disable: 'mobile',
 });

function App() {
  const [pushMessageToSnackbar, setPushMessageToSnackbar] = useState(null);

  const getPushMessageFromChild = useCallback((pushMessage) => {
    setPushMessageToSnackbar(() => pushMessage);
  }, [setPushMessageToSnackbar]);

  useEffect(() => {
    const splashscreen = document.getElementById('preloader')

    if(splashscreen) {
      //fade out
      splashscreen.classList.add('available')
      setTimeout(() => {
        splashscreen.outerHTML = ''
      }, 3500)
    }
  }, [])

  return (
    <HashRouter>
      <ScrollToTop />
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light}/>
        <MouseParticles
          num={1}
          radius={20}
          life={0.5}
          v={1.5}
          alpha={0.5}
          level={1}
        />
        <ConsecutiveSnackbarMessages
          getPushMessageFromChild={getPushMessageFromChild}
        />
        <Suspense fallback={<Fragment/>}>
          <Switch>
            <Route path="/c" >
              <LoggedInComponent pushMessageToSnackbar={pushMessageToSnackbar} />
            </Route>
            <Route>
              <LoggedOutComponent pushMessageToSnackbar={pushMessageToSnackbar} />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </HashRouter>
  );
}

serviceWorker.register();

export default App;
